jQuery(document).ready(function ($) {
    // hcaptcha
    function loadHCaptcha(elementId) {
        var siteKey = "b86f6b9c-895d-4397-b3c0-42526cdc0ac5";
        var widgetId = window.hcaptcha.render(elementId, {
            sitekey: siteKey,
            size: "normal",
            callback: function (token) {
                // Handle the token (e.g., send it to your server for verification)
                console.log("hCaptcha token:", token);
            },
        });
        return widgetId;
    }

    function resetHCaptcha(elementId) {
        var widgetId = window.hcaptcha.render(elementId, {
            sitekey: siteKey,
            size: "normal",
            callback: function (token) {
                // Handle the token (e.g., send it to your server for verification)
                console.log("hCaptcha token:", token);
            },
        });
        window.hcaptcha.reset(widgetId);
    }

    //toggle locode forms
    $("input#FacilityOwnerTrue").change(function () {
        if ($(this).is(":checked")) {
            $.ajax({
                url: site_data.adminajax, // ajaxurl is a global variable in WordPress that holds the URL to admin-ajax.php
                type: "POST",
                encode: true,
                dataType: "json",
                data: {
                    action: "locodeform2", // Action to call the appropriate WordPress hook
                },
                success: function (response) {
                    $(".formswap").html(response);
                    locodeinit();
                    initAutocomplete();
                    loadHCaptcha("hcaptcha-container");
                },
                error: function (xhr, status, error) {
                    // Handle errors
                    console.error(error); // Log the error to console
                },
            });
        }
    });
    $("input#FacilityOwnerFalse").change(function () {
        if ($(this).is(":checked")) {
            $.ajax({
                url: site_data.adminajax, // ajaxurl is a global variable in WordPress that holds the URL to admin-ajax.php
                type: "POST",
                encode: true,
                dataType: "json",
                data: {
                    action: "locodeform1", // Action to call the appropriate WordPress hook
                },
                success: function (response) {
                    $(".formswap").html(response);
                    locodeinit();
                    initAutocomplete();
                    loadHCaptcha("hcaptcha-container");
                },
                error: function (xhr, status, error) {
                    // Handle errors
                    console.error(error); // Log the error to console
                },
            });
        }
    });

    $(".single-letter")
        .each(function () {
            var $this = $(this);
            $(this).data("maxlength", $this.prop("maxlength"));
            $(this).removeAttr("maxlength");
        })
        .bind("input", function () {
            var $this = $(this);
            setTimeout(function () {
                spill($this, $this.val());
            }, 0);
        });

    $(".locode-letter input")
        .each(function () {
            var $this = $(this);
            $(this).data("maxlength", $this.prop("maxlength"));
            $(this).removeAttr("maxlength");
        })
        .bind("input", function () {
            var $this = $(this);
            setTimeout(function () {
                locodespill($this, $this.val());
            }, 0);
        });

    function spill($this, val) {
        var maxlength = $this.data("maxlength");
        if (val.length >= maxlength) {
            $this.val(val.substring(0, maxlength));
            var next = $this.next("input").focus();
            spill(next, val.substring(maxlength));
        } else $this.val(val);
    }

    function locodespill($this, val) {
        var maxlength = $this.data("maxlength");
        if (val.length >= maxlength) {
            $this.val(val.substring(0, maxlength));
            var next = $this.parent().next("div").find("input").focus();
            locodespill(next, val.substring(maxlength));
        } else $this.val(val);
    }

    $("body").on("click", "input.showsub", function () {
        if ($(this).prop("checked") == true) {
            $("#locodesubmit").show();
        } else {
            $("#locodesubmit").hide();
        }
    });

    $("input.applysub").click(function () {
        if ($("input.applysub").prop("checked") == true) {
            $("#biccodesubmit").show();
        } else {
            $("#biccodesubmit").hide();
        }
        if ($(this).prop("checked") == true) {
            $("#locodesubmit").show();
            $("#locodesubmit2").show();
        } else {
            $("#locodesubmit").hide();
            $("#locodesubmit2").hide();
        }
    });

    $("select#00N2400000DbrD9,select#country_code").change(function () {
        var value = $(this).val();

        if ($(this).attr("id") == "country_code") {
            if (
                $("select#00N2400000DbrD9").val() != "RU" &&
                $("select#00N2400000DbrD9").val() != "BY" &&
                $("select#00N2400000DbrD9").val() != "UA" &&
                $("select#00N2400000DbrD9").val() != "IR" &&
                $("select#00N2400000DbrD9").val() != "CU" &&
                $("select#00N2400000DbrD9").val() != "KP" &&
                $("select#00N2400000DbrD9").val() != "SY"
            ) {
                if (
                    value == "RU" ||
                    value == "BY" ||
                    value == "UA" ||
                    value == "IR" ||
                    value == "CU" ||
                    value == "KP" ||
                    value == "SY"
                ) {
                    $("select#currency #currency-usd").attr(
                        "disabled",
                        "disabled"
                    );
                    $("select#currency").val("EUR");
                    // $('select#currency').niceSelect('update');
                } else {
                    $("select#currency #currency-usd").removeAttr("disabled");
                    // $('select#currency').niceSelect('update');
                }
            }
        }

        if ($(this).attr("id") == "00N2400000DbrD9") {
            if (
                $("select#country_code").val() != "RU" &&
                $("select#country_code").val() != "BY" &&
                $("select#country_code").val() != "UA" &&
                $("select#country_code").val() != "IR" &&
                $("select#country_code").val() != "CU" &&
                $("select#country_code").val() != "KP" &&
                $("select#country_code").val() != "SY"
            ) {
                if (
                    value == "RU" ||
                    value == "BY" ||
                    value == "UA" ||
                    value == "IR" ||
                    value == "CU" ||
                    value == "KP" ||
                    value == "SY"
                ) {
                    $("select#currency #currency-usd").attr(
                        "disabled",
                        "disabled"
                    );
                    $("select#currency").val("EUR");
                    // $('select#currency').niceSelect('update');
                } else {
                    $("select#currency #currency-usd").removeAttr("disabled");
                    // $('select#currency').niceSelect('update');
                }
            }
        }
    });

    //Hide Please specify input by default
    $("#00N2400000DDJyn").hide();
    $("#leadpleasespecify").hide();

    //Show Please specify input when other is selected
    $("select#lead_source").change(function () {
        var value = $(this).val();

        if (value == "Other") {
            $("#00N2400000DDJyn").show();
            $("#leadpleasespecify").show();
        } else {
            $("#00N2400000DDJyn").hide();
            $("#leadpleasespecify").hide();
        }
    });

    var validator = new FormValidator(
        "biccoderegform",
        [
            {
                name: "00N2400000AWp6C",
                display: "BIC Code preference",
                rules: "required",
            },
            {
                name: "00N2400000AWp6D",
                display: "BIC Code preference",
                rules: "required",
            },
            {
                name: "00N2400000AWp6E",
                display: "BIC Code preference",
                rules: "required",
            },
            {
                name: "currency",
                display: "Lead Currency",
                rules: "required",
            },
            {
                name: "company",
                display: "Company",
                rules: "required",
            },
            {
                name: "00N2400000AWp6B",
                display: "Department / Division",
                rules: "required",
            },
            {
                name: "first_name",
                display: "First Name",
                rules: "required",
            },
            {
                name: "last_name",
                display: "Last Name",
                rules: "required",
            },
            {
                name: "title",
                display: "Job Title",
                rules: "required",
            },
            {
                name: "phone",
                display: "Phone",
                rules: "required",
            },
            {
                name: "email",
                display: "Email",
                rules: "required",
            },
            {
                name: "street",
                display: "Address",
                rules: "required",
            },
            {
                name: "zip",
                display: "Zip / Postcode",
                rules: "required",
            },
            {
                name: "city",
                display: "City",
                rules: "required",
            },
            {
                name: "country_code",
                display: "Country",
                rules: "required",
            },
            {
                name: "00N2400000DDJyo",
                display: "Number of containers owned or planned",
                rules: "required",
            },
            {
                name: "industry",
                display: "Industry",
                rules: "required",
            },
            {
                name: "00N2400000Ctq9E",
                display: "Activity Detail",
                rules: "required",
            },
            {
                name: "lead_source",
                display: "Lead Source",
                rules: "required|callback_check_other",
            },
            {
                name: "00N2400000Iwlij",
                rules: "callback_checked",
            },
        ],
        function (errors, evt) {
            var SELECTOR_ERRORS = $(".error_box"),
                SELECTOR_SUCCESS = $(".success_box");

            //get field of hcptcha by name h-captcha-response
            var hcaptchaVal = $("[name=h-captcha-response]").val();

            if (hcaptchaVal) {
                $.ajax({
                    type: "post",
                    url: site_data.adminajax,
                    dataType: "json",
                    data: {
                        action: "hcaptcha",
                        hcaptcha: hcaptchaVal,
                    },
                    success: function (response) {
                        if (response.status == "error") {
                            var hcaptcha = {
                                id: "hcaptcha",
                                message: "The Captcha did not verify",
                            };
                            errors.push(hcaptcha);
                        }

                        if (errors.length > 0) {
                            SELECTOR_ERRORS.empty();

                            for (
                                var i = 0, errorLength = errors.length;
                                i < errorLength;
                                i++
                            ) {
                                SELECTOR_ERRORS.append(
                                    errors[i].message + "<br />"
                                );
                            }

                            SELECTOR_SUCCESS.css({ display: "none" });
                            SELECTOR_ERRORS.fadeIn(200);
                        }
                    },
                    error: function (response) {
                        var hcaptcha = {
                            id: "hcaptcha",
                            message: "The Captcha did not verify",
                        };
                        errors.push(hcaptcha);

                        if (errors.length > 0) {
                            SELECTOR_ERRORS.empty();

                            for (
                                var i = 0, errorLength = errors.length;
                                i < errorLength;
                                i++
                            ) {
                                SELECTOR_ERRORS.append(
                                    errors[i].message + "<br />"
                                );
                            }

                            SELECTOR_SUCCESS.css({ display: "none" });
                            SELECTOR_ERRORS.fadeIn(200);
                        }
                    },
                });
            } else {
                var hcaptcha = {
                    id: "hcaptcha",
                    message: "The captcha field is required.",
                };
                errors.push(hcaptcha);

                if (errors.length > 0) {
                    SELECTOR_ERRORS.empty();

                    for (
                        var i = 0, errorLength = errors.length;
                        i < errorLength;
                        i++
                    ) {
                        SELECTOR_ERRORS.append(errors[i].message + "<br />");
                    }

                    SELECTOR_SUCCESS.css({ display: "none" });
                    SELECTOR_ERRORS.fadeIn(200);
                }
            }
        }
    );

    validator
        .registerCallback("checked", function (value) {
            if ($("input#00N2400000Iwlij").prop("checked") == true) {
                console.log("CHECKED");
                return true;
            }
            console.log("NOT CHECKED");
            return false;
        })
        .setMessage(
            "checked",
            "You must agree to the Terms and Conditions and the Privacy Notice."
        );

    validator
        .registerCallback("check_other", function (value) {
            if (
                document.getElementById("00N2400000DDJyn").style.display ==
                "none"
            ) {
                return true;
            } else {
                if (value == "Other") {
                    //check specify field in not empty else create error
                    if ($("#00N2400000DDJyn").val() != "") {
                        console.log("not empty");
                        return true;
                    }
                }
            }

            console.log($("#00N2400000DDJyn").val());

            return false;
        })
        .setMessage(
            "check_other",
            "Please specify how you learnt of the need to register a BIC code."
        );

    //
    //
    //
    //
    //
    //
    //
    //
    //
    // Locodes

    locodeinit();

    function locodeinit() {
        var validator = new FormValidator(
            "locoderegform",
            [
                {
                    name: "first_name",
                    display: "First Name",
                    rules: "required",
                },
                {
                    name: "last_name",
                    display: "Last Name",
                    rules: "required",
                },
                {
                    name: "email",
                    display: "Email",
                    rules: "required",
                },
                {
                    name: "phone",
                    display: "Phone",
                    rules: "required",
                },
                {
                    name: "street",
                    display: "Street",
                    rules: "required",
                },
                {
                    name: "zip",
                    display: "Zip / Post Code",
                    rules: "required",
                },
                {
                    name: "city",
                    display: "City",
                    rules: "required",
                },
                {
                    name: "country_code",
                    display: "Country",
                    rules: "required",
                },
                {
                    name: "00N2400000DKxzA",
                    display: "Company Name",
                    rules: "required",
                },
                {
                    name: "00N2400000E8Bz5",
                    display: "Company Street",
                    rules: "required",
                },
                {
                    name: "00N2400000E8Bz6",
                    display: "Company Zip / Postal Code",
                    rules: "required",
                },
                {
                    name: "00N2400000E8Bz2",
                    display: "Company City",
                    rules: "required",
                },
                {
                    name: "00N2400000E8Bz3",
                    display: "Company Country",
                    rules: "required",
                },
                {
                    name: "00N2400000Iwlij",
                    rules: "callback_checked",
                },
            ],
            function (errors, evt) {
                /*
                 * DO NOT COPY AND PASTE THIS CALLBACK. THIS IS CONFIGURED TO WORK ON THE DOCUMENTATION PAGE ONLY.
                 * YOU MUST CUSTOMIZE YOUR CALLBACK TO WORK UNDER YOUR ENVIRONMENT.
                 */

                var SELECTOR_ERRORS = $(".error_box"),
                    SELECTOR_SUCCESS = $(".success_box");

                //get field of hcptcha by name h-captcha-response
                var hcaptchaVal = $("[name=h-captcha-response]").val();

                if (hcaptchaVal) {
                    $.ajax({
                        type: "post",
                        url: site_data.adminajax,
                        dataType: "json",
                        data: {
                            action: "hcaptcha",
                            hcaptcha: hcaptchaVal,
                        },
                        success: function (response) {
                            if (response.status == "error") {
                                var hcaptcha = {
                                    id: "hcaptcha",
                                    message: "The Captcha did not verify",
                                };
                                errors.push(hcaptcha);
                            }

                            if (errors.length > 0) {
                                SELECTOR_ERRORS.empty();

                                for (
                                    var i = 0, errorLength = errors.length;
                                    i < errorLength;
                                    i++
                                ) {
                                    SELECTOR_ERRORS.append(
                                        errors[i].message + "<br />"
                                    );
                                }

                                SELECTOR_SUCCESS.css({ display: "none" });
                                SELECTOR_ERRORS.fadeIn(200);
                            }
                        },
                        error: function (response) {
                            var hcaptcha = {
                                id: "hcaptcha",
                                message: "The Captcha did not verify",
                            };
                            errors.push(hcaptcha);

                            if (errors.length > 0) {
                                SELECTOR_ERRORS.empty();

                                for (
                                    var i = 0, errorLength = errors.length;
                                    i < errorLength;
                                    i++
                                ) {
                                    SELECTOR_ERRORS.append(
                                        errors[i].message + "<br />"
                                    );
                                }

                                SELECTOR_SUCCESS.css({ display: "none" });
                                SELECTOR_ERRORS.fadeIn(200);
                            }
                        },
                    });
                } else {
                    var hcaptcha = {
                        id: "hcaptcha",
                        message: "The captcha field is required.",
                    };
                    errors.push(hcaptcha);

                    if (errors.length > 0) {
                        SELECTOR_ERRORS.empty();

                        for (
                            var i = 0, errorLength = errors.length;
                            i < errorLength;
                            i++
                        ) {
                            SELECTOR_ERRORS.append(
                                errors[i].message + "<br />"
                            );
                        }

                        SELECTOR_SUCCESS.css({ display: "none" });
                        SELECTOR_ERRORS.fadeIn(200);
                    }
                }
            }
        )
            .registerCallback("checked", function (value) {
                if ($("input#00N2400000Iwlij").prop("checked") == true) {
                    console.log("CHECKED");
                    return true;
                }
                console.log("NOT CHECKED");
                return false;
            })
            .setMessage(
                "checked",
                "You must agree to the Terms and Conditions and the Privacy Notice."
            );

        // alert('hi')

        // $('body').on('change', '#00N2400000DDJyq', function(event){
        //     event.preventDefault();
        //     console.log('fireme');
        // });
    }
});
